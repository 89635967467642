<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Inspections</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0" id="inspections_heading">Inspections</h3>
            </div>
          </div>
          <div>
            <div class="col-12 text-right">
              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Press Enter to search..."
                  @keyup.enter="search"
                ></base-input>
              </div>
            </div>

            <el-table
              :data="inspections"
              row-key="enquiry_id"
              id="inspections_data_table"
              v-loading="loading"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @row-click="rowClicked"
            >
              <el-table-column label="Name" prop="name" sortable="custom">
                <template v-slot="{ row }">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.fullName
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Event Type" prop="eventType" >
                <template v-slot="{ row }">
                  {{ row.eventType }}
                </template>
              </el-table-column>

              <el-table-column
                label="Inspections Booked"
                prop="inspections"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  {{ row.inspections_count }}
                </template>
              </el-table-column>

              <el-table-column
                label="Inspections Date"
                prop="inspectionDate"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  {{ (row.last_inspection_date) || ''  | moment("DD MMM YYYY")}}
                </template>
              </el-table-column>

              <el-table-column label="Budget" prop="estimatedBudget"  >
                <template v-slot="{ row }"> ${{ row.estimatedBudget }} </template>
              </el-table-column>

              <el-table-column label="Guests" prop="estimatedGuests"  >
                <template v-slot="{ row }">
                  {{ row.estimatedGuests }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import tableMixin from '@/helpers/remoteTableMixin';
import {InspectionService} from "@/services";

export default {
  mixins: [tableMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      inspections: [],
      loading: true,
    };
  },
  created() {
    this.loadPage();
  },
  methods: {
    async loadPage(page, search, sortBy, sortOrder) {
      this.loading = true;
      let inspectionsData = await InspectionService.paginate(page, search, sortBy, sortOrder, this.country);
      this.inspections = inspectionsData.data;
      this.pagination.total = parseInt(inspectionsData.meta.total);
      this.pagination.perPage = parseInt(inspectionsData.meta.per_page);
      this.loading = false;
    },
    rowClicked(row) {
      const id = row && row.id || 0;
      this.$router.push({ name: 'LeadDetails', params: {id: row.id} });
    },
  },
  computed: {
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
